import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { MAM_NON_PACKAGE, PACKAGES, getAuthorizedMenus } from 'components/app-navigator/navMenus';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        minHeight: 'calc(100vh - 56px)',
        display: 'flex',
        paddingTop: '10%',
    },
    container: {
        height: '100%',
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        minHeight: 300,
        minWidth: 300,
        padding: theme.spacing(3),
    },
    listHeader: {
        fontSize: 24,
        fontWeight: 'bold',
    },
}));

function MamNonPage() {
    const classes = useStyles();
    const history = useHistory();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);

    const roles = get(authenticationReducer, 'userInfo.roles', []);

    const authorizedMenus = getAuthorizedMenus(roles);
    const mamNonMenus = authorizedMenus.filter((item) => !item.disabled && (item.package === PACKAGES.MAM_NON || item.package === PACKAGES.MAM_NON_USER));
    const studentInfoItems = mamNonMenus.length > 0 ? mamNonMenus[0].subMenus.filter((item) => item.package === MAM_NON_PACKAGE.STUDENT_INFO) : [];
    const invoiceItems = mamNonMenus.length > 0 ? mamNonMenus[0].subMenus.filter((item) => item.package === MAM_NON_PACKAGE.ATTENDANCE_AND_INVOICE) : [];
    const paymentItems = mamNonMenus.length > 0 ? mamNonMenus[0].subMenus.filter((item) => item.package === MAM_NON_PACKAGE.PAYMENT) : [];

    return (
        <div className={classes.wrapper}>
            <Container maxWidth="md" className={classes.container}>
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
                                        {studentInfoItems.length > 0 && <ListItem className={classes.listHeader}>{MAM_NON_PACKAGE.STUDENT_INFO}</ListItem>}
                                        {studentInfoItems.map((menu, index) => (
                                            <ListItem button onClick={() => history.push(menu.path)} key={index}>
                                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                                <ListItemText primary={menu.title} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
                                        {invoiceItems.length > 0 && <ListItem className={classes.listHeader}>{MAM_NON_PACKAGE.ATTENDANCE_AND_INVOICE}</ListItem>}
                                        {invoiceItems.map((menu, index) => (
                                            <ListItem button onClick={() => history.push(menu.path)} key={index}>
                                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                                <ListItemText primary={menu.title} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
                                        {paymentItems.length > 0 && <ListItem className={classes.listHeader}>{MAM_NON_PACKAGE.PAYMENT}</ListItem>}
                                        {paymentItems.map((menu, index) => (
                                            <ListItem button onClick={() => history.push(menu.path)} key={index}>
                                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                                <ListItemText primary={menu.title} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
}

export default MamNonPage;
