import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import SortIcon from '@material-ui/icons/Sort';
import clsx from 'clsx';
import { FMI_SERVER_DATE_FORMAT } from 'config/constants/dateConstants';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { ROLES } from 'config/constants/roleConstant';
import dayjs from 'dayjs';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { default as ReactSelect, default as Select } from 'react-select';
import { callApiGetListOfClasses } from 'redux/saga/mam-non/info/class/getListOfClasses';
import { callApiReorderStudents } from 'redux/saga/mam-non/info/class/reorderStudents';
import { callApiExportStudents } from 'redux/saga/mam-non/info/class/student/exportStudents';
import { callApiGetListStudent } from 'redux/saga/mam-non/info/class/student/getListStudents';
import { callApiGetListOfGrades } from 'redux/saga/mam-non/info/grade/getListOfGrades';
import { callApiExportThuDauNam } from 'redux/saga/mam-non/info/thu-dau-nam/exportThuDauNam';
import { academic_year__get_list, loaded, loading } from '../actions';
import ImportStudentDialog from './students/ImportStudentDialog';
import StudentDialog from './students/StudentDialog';
import StudentList from './students/StudentList';

const defaultStudent = {
    firstName: '',
    lastName: '',
    gender: 'MALE',
    dateOfBirth: dayjs().format(FMI_SERVER_DATE_FORMAT),
    entryDate: dayjs().format(FMI_SERVER_DATE_FORMAT),
    fatherName: '',
    fatherPhone: '',
    motherName: '',
    motherPhone: '',
};

function MamNonDanhSachPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [grades, setGrades] = useState([]);
    const [grade, setGrade] = useState('');
    const [clazzes, setClazzes] = useState([]);
    const [students, setStudents] = useState();
    const [student, setStudent] = useState(null);
    const [open, setOpen] = useState(false);
    const [openImport, setOpenImport] = useState(false);
    const [optionClassName, setOptionClassName] = useState('has-default');
    const { control, errors, setValue, getValues, trigger, watch } = useForm({
        defaultValues: {
            option: { value: 'ALL', label: 'Tất cả' },
        },
    });
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);

    const roles = get(authenticationReducer, 'userInfo.roles', []);
    const isMamNonViewer = roles.indexOf(ROLES.ROLE_MAMNON_VIEWER) >= 0;

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
        setValue('option', { value: 'ALL', label: 'Tất cả' });
    }, [dispatch, setValue]);

    const getStudents = useCallback(
        async (clazzId) => {
            setStudents(null);
            let activeStudents = [];
            let inactiveStudents = [];
            if (values.option.value === 'ALL') {
                const data1 = await callApiGetListStudent(clazzId);
                activeStudents = data1.data;
                const data2 = await callApiGetListStudent(clazzId, false);
                inactiveStudents = data2.data;
            }
            if (values.option.value === 'ACTIVE') {
                const data1 = await callApiGetListStudent(clazzId);
                activeStudents = data1.data;
            }
            if (values.option.value === 'DELETED') {
                const data2 = await callApiGetListStudent(clazzId, false);
                inactiveStudents = data2.data;
            }

            setStudents([
                ...activeStudents.map((item) => {
                    return { ...item, ...{ isActive: true } };
                }),
                ...inactiveStudents.map((item) => {
                    return { ...item, ...{ isActive: false } };
                }),
            ]);
        },
        [values]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.clazz && values.clazz.id) {
            getStudents(values.clazz.id);
        }
    }, [values, watches, prevValues, getStudents]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setGrade('');
        setClazzes([]);
        setValue('clazz', '');
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfGrades({ params: { congdoanId: authenticationReducer.userInfo.congDoanId, academicYear: selectedOption.value } })
                .then((res) => {
                    setGrades(res.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    };

    const onGradeChange = (selectedOption) => {
        setValue('clazz', '');
        setGrade(selectedOption);
        setStudents(null);
        callApiGetListOfClasses(selectedOption.value.id)
            .then((res) => {
                setClazzes(res.data);
            })
            .catch((error) => {
                messageHelper.showError(error);
            });
    };

    const onClazzChange = (selectedOption) => {
        setValue('clazz', selectedOption.value);
        trigger('clazz');
    };

    const onOptionChange = (selectedOption) => {
        setValue('option', selectedOption);
        if (optionClassName !== '') {
            setOptionClassName('');
        }
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const addStudent = () => {
        setStudent(defaultStudent);
        openDialog();
    };

    const onAddStudentSuccess = () => {
        getStudents(values.clazz.id);
    };

    const onEditStudentSuccess = () => {
        getStudents(values.clazz.id);
    };

    const exportThuDauNam = () => {
        dispatch(loading());
        callApiExportThuDauNam(values.clazz.id)
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${values.clazz.className}_Bảng thu đầu năm.xlsx`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                toastHelper.error('Có lỗi xảy ra. Vui lòng thử lại sau!');
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    const exportStudents = () => {
        dispatch(loading());
        callApiExportStudents(values.clazz.id)
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${values.clazz.className}.xlsx`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                toastHelper.error('Có lỗi xảy ra. Vui lòng thử lại sau!');
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    const importStudents = () => {
        setOpenImport(true);
    };

    function closeImportDialog() {
        setOpenImport(false);
    }

    const onImportSuccess = () => {
        getStudents(values.clazz.id);
        closeImportDialog();
    };

    const onDeleteSuccess = () => {
        getStudents(values.clazz.id);
    };

    const onRestoreSuccess = () => {
        getStudents(values.clazz.id);
    };

    const reorderStudents = () => {
        dispatch(loading());
        callApiReorderStudents(values.clazz.id)
            .then((res) => {
                toastHelper.success('Sắp xếp học sinh thành công!');
                getStudents(values.clazz.id);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Danh sách học sinh</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="col-md-4 fmi-label pr-0 mb-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={academicYearsOptions}
                                            id="academicYear"
                                            onChange={onAcademicYearChange}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="row mr-0 mt-2">
                                    <label className="col-md-4 fmi-label mb-0">Lớp:</label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="clazz"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                                },
                                            }}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        onChange={onClazzChange}
                                                        options={clazzes.map((item) => {
                                                            return { value: item, label: item.className };
                                                        })}
                                                        value={value !== '' ? value.label : ''}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.clazz && <span className="text-danger">{errors.clazz.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row mr-0">
                                    <label className="col-md-4 fmi-label" htmlFor="academicYear">
                                        Khối:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={grades.map((item) => {
                                                return { value: item, label: item.name };
                                            })}
                                            id="academicYear"
                                            onChange={onGradeChange}
                                            value={grade}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="row mr-0">
                                    <label className="col-md-4 fmi-label" htmlFor="option">
                                        Xem:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="option"
                                            control={control}
                                            render={({ value }) => {
                                                return (
                                                    <ReactSelect
                                                        options={[
                                                            { value: 'ALL', label: 'Tất cả' },
                                                            { value: 'ACTIVE', label: 'Học sinh đang học' },
                                                            { value: 'DELETED', label: 'Học sinh đã xoá' },
                                                        ]}
                                                        value={value}
                                                        onChange={onOptionChange}
                                                        id="option"
                                                        classNamePrefix="fmi-react-select"
                                                        className={optionClassName}
                                                        styles={customSelectStyles}
                                                    ></ReactSelect>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {values.clazz && values.clazz.id && (
                                <div className="row m-0 col-6 d-flex justify-content-end p-0">
                                    <div className="col-md-4">
                                        <div className="row mr-0 d-flex justify-content-end">
                                            <div className="col-md-12 p-0 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="export-button btn btn-primary fmi-toolbar-button"
                                                    onClick={reorderStudents}
                                                    title="Sắp xếp lại danh sách"
                                                >
                                                    <SortIcon />
                                                    Sắp xếp lại danh sách
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        {!isMamNonViewer && (
                                            <div className="row mr-0 d-flex justify-content-end">
                                                <div className="col-md-12 p-0 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="export-button btn btn-primary fmi-toolbar-button"
                                                        onClick={importStudents}
                                                        title="Nhập học sinh"
                                                    >
                                                        <PublishIcon />
                                                        Nhập học sinh
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        <div className={clsx('row mr-0 d-flex justify-content-end', !isMamNonViewer && 'mt-2')}>
                                            <div className="col-md-12 p-0 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="export-button btn btn-primary fmi-toolbar-button text-small"
                                                    onClick={exportStudents}
                                                    title="Nhập học sinh"
                                                >
                                                    Xuất dữ liệu học sinh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pr-0">
                                        {!isMamNonViewer && (
                                            <div className="row mr-0 d-flex justify-content-end">
                                                <div className="col-md-12 p-0 d-flex justify-content-end">
                                                    <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={addStudent} title="Thêm học sinh">
                                                        <AddIcon />
                                                        Thêm học sinh
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        <div className={clsx('row mr-0 d-flex justify-content-end', !isMamNonViewer && 'mt-2')}>
                                            <div className="col-md-12 p-0 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="export-button btn btn-primary mb-1 fmi-toolbar-button text-small"
                                                    onClick={exportThuDauNam}
                                                    title="Xuất excel thu đầu năm"
                                                >
                                                    Xuất excel thu đầu năm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
            {students && (
                <StudentList
                    students={students}
                    classId={values.clazz.id}
                    clazz={values.clazz}
                    onEditSuccess={onEditStudentSuccess}
                    onDeleteSuccess={onDeleteSuccess}
                    onRestoreSuccess={onRestoreSuccess}
                    readOnly={isMamNonViewer}
                />
            )}
            {student && (
                <StudentDialog
                    student={student}
                    students={students}
                    open={open}
                    closeDialog={closeDialog}
                    classId={values.clazz.id}
                    clazz={values.clazz}
                    onSuccess={onAddStudentSuccess}
                />
            )}
            {values.clazz && <ImportStudentDialog clazz={values.clazz} open={openImport} closeDialog={closeImportDialog} onSuccess={onImportSuccess} />}
        </>
    );
}

export default MamNonDanhSachPage;
