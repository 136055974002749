import { Container, Grid, makeStyles } from '@material-ui/core';
import { getAuthorizedMenus } from 'components/app-navigator/navMenus';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import OverviewItem from './OverviewItem';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        minHeight: 'calc(100vh - 56px)',
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        height: '100%',
    },
    root: {
        flexGrow: 1,
    },
}));

function HomePage() {
    const classes = useStyles();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const roles = get(authenticationReducer, 'userInfo.roles', []);

    const authorizedMenus = getAuthorizedMenus(roles);

    return (
        <div className={classes.wrapper}>
            <Container maxWidth="md" className={classes.container}>
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        {authorizedMenus &&
                            authorizedMenus
                                .filter((menu) => !menu.disabled)
                                .map((item, index) => (
                                    <Grid item xs={6} key={index}>
                                        <OverviewItem menu={item} />
                                    </Grid>
                                ))}
                    </Grid>
                </div>
            </Container>
        </div>
    );
}

export default HomePage;
