import { Box, Card, CardActionArea, CardContent, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: 300,
        minWidth: 300,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    typo: {
        textAlign: 'center',
        marginTop: 8,
    },
    iconBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
}));

function OverviewItem({ menu }) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Card className={classes.root} onClick={() => history.push(menu.path)}>
            <CardActionArea>
                <CardContent>
                    <Box className={classes.iconBox}>{menu.icon}</Box>
                    <Typography variant="h5" color="textSecondary" component="p" className={classes.typo}>
                        {menu.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default OverviewItem;
