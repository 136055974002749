import { ROLES } from 'config/constants/roleConstant';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import protectedRoutes, {
    banNganhRoutes,
    errorRoutes,
    homeRoutes,
    mamNonRoutes,
    mamNonUserRoutes,
    mamNonViewerRoutes,
    noiTruRoutes,
    trungUongRoutes,
    vungRoutes,
} from '../../../config/routes/protectedRoutes';

function ProtectedSwitcher() {
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const roles = get(authenticationReducer, 'userInfo.roles', []);
    const isNoiTruAdmin = roles.indexOf(ROLES.ROLE_NOITRU_ADMIN) >= 0;
    const isMamNonAdmin = roles.indexOf(ROLES.ROLE_MAMNON_ADMIN) >= 0;
    const isMamNonViewer = roles.indexOf(ROLES.ROLE_MAMNON_VIEWER) >= 0;
    const isTrungUongAdmin = roles.indexOf(ROLES.ROLE_TRUNGUONG_ADMIN) >= 0;
    const isMamNonUser = roles.indexOf(ROLES.ROLE_MAMNON_USER) >= 0;
    const isBanNganhAdmin = roles.indexOf(ROLES.ROLE_BANNGANH_ADMIN) >= 0;
    const isVungAdmin = roles.indexOf(ROLES.ROLE_VUNG_ADMIN) >= 0;

    const routes = () => {
        let routes = protectedRoutes;
        if (isNoiTruAdmin) {
            routes = [...routes, ...noiTruRoutes];
        }
        if (isMamNonAdmin) {
            routes = [...routes, ...mamNonRoutes];
        }
        if (isMamNonViewer) {
            routes = [...routes, ...mamNonViewerRoutes];
        }
        if (isTrungUongAdmin) {
            routes = [...routes, ...trungUongRoutes];
        }
        if (isBanNganhAdmin) {
            routes = [...routes, ...banNganhRoutes];
        }
        if (isVungAdmin) {
            routes = [...routes, ...vungRoutes];
        }

        if (isMamNonUser) {
            return [...homeRoutes, ...mamNonUserRoutes];
        }

        return [...routes, ...errorRoutes];
    };

    return (
        <Switch>
            {routes().map((route, index) => {
                return <Route exact key={index} path={route.path} component={route.component} />;
            })}
        </Switch>
    );
}

export default ProtectedSwitcher;
